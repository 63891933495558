import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/UI/PageTitle"

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="À propos" />
      <div className="flex-grow">
        <PageTitle title="À propos" />

        <div className="container mx-auto">
          <p>En construction...</p>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage